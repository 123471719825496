export const types = {
  TOGGLE_THEME: "TOGGLE_THEME",
  CURSOR_TYPE: "CURSOR_TYPE",
  NAV_IS_OPEN: "NAV_IS_OPEN",
}

export const CursorType = {
  DEFAULT: "default",
  HOVER: "hover",
  BIG: "big",
}

export const Themes = {
  dark: "dark",
  light: "light",
}
