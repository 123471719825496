module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mellowframes - Branding, Storytelling and Digital Experiences.","short_name":"mellowframes.","start_url":"/","background_color":"#F9015A","theme_color":"#F9015A","display":"minimal-ui","icon":"src/images/m-sm.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"924d91276a83c9970427b62a070a5a75"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
