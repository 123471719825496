import React from "react"
import { AnimatePresence } from "framer-motion"

import { GlobalProvider } from "./src/context/globalContext"

export const wrapRootElement = ({ element }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <GlobalProvider>{element}</GlobalProvider>
    </AnimatePresence>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location
  const scrollToTopRoutes = [`/`, `/about`, `/careers`, `/contact`, `/works`]
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0)
  }

  return false
}
