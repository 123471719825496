import React, { createContext, useReducer, useContext } from "react"

// Cursor type
import { types, Themes, CursorType } from "./../constants"

// Defining the context
export const GlobalStateContext = createContext({
  currentTheme: Themes.light,
  cursorType: CursorType.DEFAULT,
  navIsOpen: false,
})
export const GlobalDispatchContext = createContext()

// Reducer
const globalReducer = (state, action) => {
  switch (action.type) {
    case types.TOGGLE_THEME: {
      return {
        ...state,
        currentTheme: action.theme,
      }
    }
    case types.CURSOR_TYPE: {
      return {
        ...state,
        cursorType: action.cursorType,
      }
    }
    case types.NAV_IS_OPEN: {
      return {
        ...state,
        navIsOpen: action.navIsOpen,
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

// Global Context Provider
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, {
    currentTheme: Themes.light,
    cursorType: CursorType.DEFAULT,
    navIsOpen: false,
  })

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

// Custom hooks to use dispatch and state
export const useGlobalStateContext = () => useContext(GlobalStateContext)
export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
